import React from 'react'
import { graphql } from 'gatsby'
import { Section, Wysiwyg, Heading } from 'src/components/Base'
import Seo from 'src/components/Seo'

import {
  Hero,
  Logos,
  SalesArguments,
  ContactPerson,
  TimeLine,
} from 'src/sections'

const Services = ({ data }) => {
  const page = data.wpPage
  const hero = page.atspHero
  const intro = page.atspServices.servicesIntro
  const logos = page.atspServices.servicesLogos
  const salesArguments = page.atspServices.servicesSalesArguments
  const contact = page.atspServices.servicesContact
  const contactData = page.atspServices.servicesContact.contactPerson
  const portrait =
    contactData.atspcontacts.portrait.localFile.childImageSharp
      .gatsbyImageData || undefined
  const altText = contactData.atspcontacts.portrait.altText || undefined

  const timeline = page.atspServices.servicesTimeline

  return (
    <>
      <Seo
        title={page.seo.title}
        locale={page.language.locale}
        description={page.seo.metaDesc}
      />
      <Hero imageData={hero.heroImage.localFile} heading={hero.heroHeading} />
      <Section hasBackground>
        <Heading isCentered color="dark" as="h2" size={2}>
          {intro.heading}
        </Heading>
        <Wysiwyg isCentered>{intro.wysiwyg}</Wysiwyg>
      </Section>
      <TimeLine
        heading={timeline.heading}
        intro={timeline.wysiwyg}
        items={timeline.timelineRepeater}
        hasReadMore={timeline.timelineHasReadMore}
      />
      <SalesArguments
        heading={salesArguments.heading}
        intro={salesArguments.wysiwyg}
        salesArgumentsRepeater={salesArguments.repeater}
        hasBackground
      />
      <Logos
        heading={logos.heading}
        logos={logos.repeater}
        button={logos.button ? logos.button : undefined}
      />
      <ContactPerson
        heading={contact.heading}
        name={contactData.title}
        extensionNumber={contactData.atspcontacts.contactData.extension}
        email={contactData.atspcontacts.contactData.email}
        portrait={portrait}
        altText={altText}
      />
    </>
  )
}

export const pageQuery = graphql`
  query ServicesById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      language {
        locale
      }
      seo {
        title
        metaDesc
      }
      atspServices {
        servicesLogos {
          heading
          button {
            title
            url
            target
          }
          repeater {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
        servicesIntro {
          heading
          wysiwyg
        }
        servicesSalesArguments {
          heading
          wysiwyg
          repeater {
            heading
            wysiwyg
            icon {
              localFile {
                publicURL
              }
            }
          }
        }
        servicesContact {
          heading
          contactPerson {
            ... on WpKontakt {
              title
              id
              atspcontacts {
                contactData {
                  email
                  extension
                }
                portrait {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
        servicesTimeline {
          heading
          wysiwyg
          timelineRepeater {
            heading
            text
            button {
              title
              url
              target
            }
            icon {
              localFile {
                publicURL
              }
            }
          }
          timelineHasReadMore
        }
      }
      atspHero {
        heroHeading
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, width: 2880, quality: 90)
            }
          }
        }
      }
    }
  }
`

export default Services
